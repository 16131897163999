.block--image {
  &.cms--block {
    display: flex;
    &.align {
      &-center {
        justify-content: center;
      }
      &-left {
        justify-content: flex-start;
      }
      &-right {
        justify-content: flex-end;
      }
    }
  }
}
